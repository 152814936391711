<template>
  <div class="Details" v-if="Details" :style="Control">
    <div class="close" @click="closeImg"></div>
    <div class="collect" v-bind:class="{collects: CollectInShow}" @click="work"></div>
    <div class="openCom" @click="openCom" v-if="!commentStart"></div>
    <div class="openImg" @click="openImgs" v-if="commentStart"></div>
    <div class="main" id="posterHtml" ref="imageTest">
      <canvas ref="canvas" class="canvas"></canvas>
      <img :src="bgSum" class="bgSum" v-if="bgSum">
      <div class="comment" v-if="commentStart">
        <div class="title"><i></i>全部评论</div>
        <div class="data">
          <div class="block" v-for="(item,index) in contentData" :key="index">
            <div class="basic">
              <div class="avatar"><img :src="item.Avatar"></div>
              <div class="PenName">{{ item.UserName }}</div>
              <div class="Time">{{ item.Time }}</div>
            </div>
            <div class="textBlock">
              <div class="text">{{ item.Data }}</div>
            </div>
          </div>
        </div>
        <div class="release">
          <input type="text" placeholder="快来发布一篇评论吧～" @keydown.enter="release" v-model="content">
          <div class="send" @click="release">发表</div>
        </div>
      </div>
    </div>
    <Load ref="Load"></Load>
  </div>
</template>

<script>
import Load from "./load.vue";
import {
  ActivityFind,
  addEvaluate,
  base64Img,
  base64QR, findEvaluate, setkork, workFindID,
} from "../api/exhibit";

import {Notify, Toast} from "vant";


export default {
  data() {
    return {
      Control: '',
      Details: false,
      commentStart: false,

      CollectInShow: false,

      content: '',
      contentData: [],

      el: '',
      ctx: '',
      width: 1000,
      height: 2100,
      WorkInfo: {},
      bgSum: '',
      //判断图片是否是横向竖向
      isHorizontal: false,
      posterImage: ''
    }
  },
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem("user"))
    this.userInfo = userInfo
    let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
    this.ActivityInfo = ActivityInfo
  },
  components: {
    Load
  },
  methods: {
    //判断图片是否是横向竖向
    image_container() {
      this.$refs.Load.open()
      base64Img({ImageUrl: this.WorkInfo.ImageUrl}).then(res => {
        let img = new Image()
        let payload = res.payload
        img.src = payload
        img.onload = (() => {
          if (img.naturalWidth >= img.naturalHeight) {
            //横向
            this.isHorizontal = true;
          } else {
            //竖向
            this.isHorizontal = false;
          }
          this.initcanvas()
          this.initWork()
        })
      })
    },
    //点赞作品
    work() {
      let WorkID = this.WorkID
      let ActivityID = this.ActivityInfo.id
      let UserID = this.userInfo.id
      let CompanyID = JSON.parse(localStorage.getItem("CompanyID"))

      setkork({WorkID, UserID, ActivityID, CompanyID}).then(res => {
        if (res.errorCode == 200) {
          Toast.success(res.payload);
          this.initWork()
        } else {
          Notify({type: 'warning', message: res.errorMsg});
        }
      })
    },
    //添加评论内容
    release() {
      let that = this
      let CompanyID = JSON.parse(localStorage.getItem("CompanyID"))
      let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
      let userInfo = JSON.parse(localStorage.getItem("user"))

      let UserID = userInfo.id
      let ActivityID = ActivityInfo.id
      let WorkInfo = this.WorkInfo
      let WorkID = WorkInfo.id
      let Data = this.content
      if (Data) {
        addEvaluate({CompanyID, ActivityID, UserID, WorkID, Data}).then(res => {
          if (res.errorCode == 200) {
            that.findrelease()
            that.content = ''
            Toast.success(res.payload);
          } else {
            Toast.fail(res.errorMsg);
          }
        })
      } else {
        Toast.fail('评论内容未填写');
      }
    },
    openImg(item) {
      console.log(item)
      this.Details = true
      this.WorkID = item.id
      this.WorkInfo = item
      this.findposterImage()
      setTimeout(() => {
        this.image_container()
      })
    },
    //作品数据初始化(点赞)
    initWork() {
      let WorkID = this.WorkID
      let UserID = this.userInfo.id
      workFindID({WorkID, UserID}).then(res => {
        if (res.errorCode == 200) {
          let payload = res.payload
          let CollectInShow = payload.CollectInShow
          this.CollectInShow = CollectInShow
        } else {
          Notify({type: 'warning', message: res.errorMsg});
        }
      })
    },
    openCom() {
      this.contentData = []
      this.commentStart = true
      this.content = ''
      this.findrelease()
    },
    findrelease() {
      let WorkInfo = this.WorkInfo
      let WorkID = WorkInfo.id
      findEvaluate({WorkID}).then(res => {
        if (res.errorCode == 200) {
          let data = res.payload
          for (let i in data) {
            let time = data[i].Time
            let day = new Date(time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          }

          this.contentData = data
        } else {
          Toast(res.errorMsg);
        }
      })
    },
    openImgs() {
      this.contentData = []
      this.commentStart = false
      this.content = ''
    },
    findposterImage() {
      return new Promise((resolve) => {
        let WorkInfo = this.WorkInfo
        let ActivityID = WorkInfo.ActivityID
        ActivityFind({ActivityID}).then(res => {
          let payload = res.payload
          let PosterImage = payload.PosterImage

          this.posterImage = PosterImage
          resolve()
        })
      })
    },
    initcanvas() {
      this.$refs.Load.open()
      //生成初始化
      let canvas = this.$refs.canvas

      // 定义长高
      canvas.width = this.width
      canvas.height = this.height
      // 转换2d对象
      let crx = canvas.getContext('2d')
      //填充背景白色颜色
      crx.fillStyle = '#fff'
      crx.fillRect(0, 0, this.width, this.height);

      //倍率设置
      // crx.translate(3, 3);
      //红框（开发定位，生产勿用）
      // crx.lineWidth = 1;
      // crx.strokeStyle = "#800354";
      // crx.strokeRect(75, 330, 850, 1300);

      // PosterImage


      //渲染背景底图
      let p1 = new Promise((resolve) => {
        let ImageUrl = this.posterImage
        base64Img({ImageUrl}).then(res => {
          let img = new Image()
          let payload = res.payload
          img.src = payload
          img.onload = (() => {
            crx.drawImage(img, 0, 0, 1000, 2100);

            let name = this.WorkInfo.UserName
            let penName = this.WorkInfo.PenName
            if (name.length >= 10) {
              name = name.slice(0, 10) + '...'
            }
            if (penName.length >= 10) {
              penName = penName.slice(0, 10) + '...'
            }
            crx.fillStyle = '#efa071';
            crx.font = "normal 300 40px Calibri";
            crx.fillText(this.WorkInfo.WorkNumber, 255, 1709);
            crx.fillStyle = '#efa071';
            crx.font = "normal 300 40px Calibri";
            crx.fillText(penName, 255, 1765);
            crx.fillStyle = '#efa071';
            crx.font = "normal 300 40px Calibri";
            crx.fillText(name, 255, 1825);


            crx.fillText(this.WorkInfo.Time, 115, 1915);

            resolve('success')
          })
        })
      })

      // 渲染作品
      let p2 = new Promise((resolve) => {
        base64Img({ImageUrl: this.WorkInfo.ImageUrl}).then(res => {
          let img = new Image()
          let payload = res.payload
          img.src = payload
          img.onload = (() => {
            crx.drawImage(img, 123, 380, 769, 1206);
            resolve('success')
          })
        })
      })


      //渲染二维码
      let p4 = new Promise((resolve) => {
        let CompanyID = JSON.parse(localStorage.getItem("CompanyID"));
        let urltext = "http://wrtz.3qlab.com/" + `?CompanyID=${CompanyID}&WorkID=${this.WorkInfo.id}`;
        base64QR({url: urltext}).then(res => {
          if (res.errorCode == "200") {
            let img = new Image();
            img.src = res.payload;
            img.onload = (() => {
              crx.drawImage(img, 642, 1645, 285, 275);
              resolve('success')
            })
          }
        }).catch((error) => {
          console.log(error);
        })
      })


      Promise.all([p1, p2, p4]).then(() => {
        const imgBase64s = canvas.toDataURL("image/png")
        this.bgSum = imgBase64s
        this.$refs.Load.close()
        Toast('请长按图片进行保存');
      }).catch((error) => {
        console.log(error)
      })
    },
    createQrcode(text) {
      const qrcodeImgEl = this.$refs.qrcodeImg
      qrcodeImgEl.innerHTML = "";

      base64QR({url: text}).then(res => {
        let img = `<img src="${res}"/>`
        qrcodeImgEl.innerHTML = img;
      })

      setTimeout(() => {
        this.initcanvas()
      }, 400)
    },
    closeImg() {
      this.img = ''
      this.name = ''
      this.time = ''
      this.penName = ''
      this.bgSum = ''
      this.commentStart = false
      this.contentData = []
      setTimeout(() => {
        this.Details = false
      }, 10)
    },
    getNunber(str) {
      if (str < 10) {
        return '0000' + str
      } else if (str < 100) {
        return '000' + str
      } else if (str < 1000) {
        return '00' + str
      } else if (str < 10000) {
        return '0' + str
      }
    },


  }
}
</script>

<style scoped lang="scss">
.Details {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  //background-color: #020202;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;


  .create {
    width: 280px;
    height: 560px;
    background-color: #FFFFFF;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -140px;
    margin-top: -280px;
  }

  .bgsimg {
    width: 280px;
    height: 560px;
    background-color: #973733;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -280px;
  }

  .close {
    width: 24px;
    height: 24px;
    background-image: url("../assets/image/map/close.png");
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: 143px;
    margin-top: -280px;
    toggle-group: 0;
    background-color: #FFFFFF;
  }

  .collect {
    width: 26px;
    height: 26px;
    background-image: url("../assets/image/create/like.png");
    background-size: 15px 15px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: 143px;
    margin-top: 200px;
    toggle-group: 0;
    background-color: #FFFFFF;
    border-radius: 15px;
  }

  .collects {
    background-image: url("../assets/image/create/likes.png") !important;
  }

  .openCom {
    width: 24px;
    height: 24px;
    background-image: url("../assets/image/exhibit/openCom.png");
    background-size: 15px 15px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: 143px;
    margin-top: 252px;
    toggle-group: 0;
    background-color: #FFFFFF;
    border-radius: 15px;
  }

  .openImg {
    width: 24px;
    height: 24px;
    background-image: url("../assets/image/exhibit/openImg.png");
    background-size: 15px 15px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: 143px;
    margin-top: 252px;
    toggle-group: 0;
    background-color: #FFFFFF;
    border-radius: 15px;
  }

  .main {
    width: 280px;
    height: 560px;
    background-color: #FFFFFF;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -140px;
    margin-top: -280px;


    .bgSum {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .comment {
      width: 280px;
      height: 560px;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #FFFFFF;

      .title {
        width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        border-bottom: #f0f0f0 1px solid;

        i {
          background-repeat: no-repeat;
          background-image: url("../assets/image/exhibit/comment.png");
          background-size: 15px 15px;
          background-position: center center;
          width: 20px;
          height: 30px;
          display: inline-block;
          float: left;
          margin-right: 10px;

        }

      }

      .data {
        width: 100%;
        height: 491px;
        overflow-y: scroll;
        @mixin text {
          color: #666;
          font-size: 11px;
          position: absolute;
        }

        .block {
          width: 100%;
          height: auto;
          background-color: #FFFFFF;

          .basic {
            width: 100%;
            height: 50px;
            position: relative;

            .avatar {
              width: 30px;
              height: 30px;
              border-radius: 50px;
              //background-color: #973733;
              position: absolute;
              left: 10px;
              top: 10px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 15px;
              }
            }

            .PenName {
              @include text;
              left: 50px;
              top: 16px;
            }

            .Time {
              @include text;
              right: 5px;
              top: 16px;
              position: absolute;
              font-size: 11px;
            }
          }

          .textBlock {
            width: 100%;
            height: 60px;
            overflow: hidden;

            .text {
              width: 230px;
              //height:-webkit-fill-available;
              height: 60px;
              margin-top: 5px;
              font-size: 12px;
              float: right;
              color: #666666;
              line-height: 25px;
            }
          }


        }
      }

      .data::-webkit-scrollbar {
        display: none;
      }

      .release {
        position: absolute;
        width: 100%;
        height: 40px;
        bottom: 0;
        left: 0;

        input {
          width: 190px;
          height: 20px;
          background-color: #f0f0f0;
          position: absolute;
          left: 10px;
          top: 8px;
          border: none;
          font-size: 12px;
          text-indent: 0.5rem;
          color: #666666;
        }

        .send {
          width: 50px;
          height: 22px;
          line-height: 22px;
          font-size: 12px;
          right: 15px;
          top: 8px;
          background-color: #4b103b;
          color: #FFFFFF;
          text-align: center;
          position: absolute;
        }
      }
    }

    .titleNav {
      width: 210px;
      height: 30px;
      position: absolute;
      left: 50%;
      margin-left: -105px;
      top: 13px;
      background-image: url("../assets/image/exhibit/titles.png");
      background-size: 100% 100%;
    }

    .qrcodeImg {
      position: absolute;
      width: 75px;
      height: 75px;
      top: 0;
      left: 0;
      opacity: 0;
    }


    .down {
      width: 24px;
      height: 24px;
      background-image: url("../assets/image/map/down.png");
      background-size: 100% 100%;
      position: absolute;
      left: 50%;
      margin-left: 13px;
      bottom: -40px;
      background-color: #FFFFFF;
      border-radius: 15px;
    }


    .img {
      width: 230px;
      height: 330px;
      position: absolute;
      left: 35px;
      top: 60px;
      border: 1px solid #973733;

      img {
        width: 100%;
        height: 100%;

      }
    }

    @mixin pos {
      position: absolute;
      font-size: 12px;
    }

    .time {
      position: absolute;
      left: 40px;
      bottom: 50px;
      font-size: 12px;
    }

    .great {
      @include pos;
      left: 40px;
      bottom: 70px;
      //width: 100px;
      height: 20px;

      i {
        margin-right: 10px;
        display: inline-block;
        background-image: url("../assets/image/create/like.png");
        width: 15px;
        height: 15px;
        background-size: 100% 100%;
      }
    }

    .step {
      @include pos;
      left: 40px;
      bottom: 94px;
      //width: 100px;
      height: 20px;

      i {
        margin-right: 10px;
        display: inline-block;
        background-image: url("../assets/image/create/step.png");
        width: 15px;
        height: 15px;
        background-size: 100% 100%;
      }
    }

    .bottom {
      width: 100%;
      height: 30px;
      position: absolute;
      bottom: 0;
      left: 0;
      overflow: hidden;

      p {
        position: absolute;
        color: #FFFFFF;
        left: 0;
        top: -12px;
        text-align: center;
        font-size: 13px;
        width: 100%;
        height: 30px;
        line-height: 30px;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
      }

      //opacity: 0;
    }


  }


  .filoink {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 145px;
    top: 460px;
    z-index: 12;
  }

  .canvas {
    position: fixed;
    top: 0;
    opacity: 0;

    //left: 50%;
    //top: 50%;
    //margin-left: -150px;
    //margin-top: -289.5px;
    z-index: -40;
  }

  .createGoTo {
    width: 280px;
    height: 35px;
    background-color: #4b103b;
    position: absolute;
    left: 50%;
    margin-left: -140px;
    top: 50%;
    margin-top: 281px;

    span {
      font-size: 13px;
      color: #FFFFFF;
      letter-spacing: 5px;
      position: absolute;
      left: 120px;
      top: 6px;
    }

    .feather {
      position: absolute;
      left: 87px;
      top: 8px;
      width: 20px;
      height: 20px;
    }

    .left {
      width: 40px;
      position: absolute;
      left: 19px;
      top: 8px;
    }

    .right {
      width: 40px;
      position: absolute;
      right: 23px;
      top: 8px;
      transform: rotate(180deg);
    }
  }
}

.GuidePage {
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
  background-image: url("https://hz-mayi.nos-eastchina1.126.net/wrtx/GuideShare.jpg");
  left: 0;
  top: 0;

  .button {
    position: absolute;
    width: 120px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    border: 2px solid #fff;
    bottom: 70px;
    left: 50%;
    margin-left: -60px;
    text-align: center;
    color: #fff;
    font-weight: bold;
  }

  .checkbox {
    position: absolute;
    font-size: 14px;
    bottom: 40px;
    left: 50%;
    margin-left: -60px;
    text-align: center;
    color: #fff;
  }
}
</style>
